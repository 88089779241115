import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import PostPage from '../components/Blog/PostPage'

export default ({ pageContext }) => {
  const { data, post } = pageContext

  return (
    <Layout data={data}>
      <SEO {...post.seo} />
      <PostPage {...pageContext} />
    </Layout>
  )
}
